<template>
    <AppIcon v-bind="$props">
        <template #default="{ color }">
            <!-- eslint-disable max-len -->
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M16 3H3C2.40523 3 2 3.40169 2 4V15.9153C2 16.3421 2.50026 16.5726 2.82476 16.2954L5.31182 14.1708C5.50518 14.0093 5.74863 14 6 14H13.4833C13.767 13.2748 14.2377 12.6657 14.7734 12.2065C15.396 11.6729 16.1679 11.2832 17 11.1061V4C17 3.40169 16.5948 3 16 3ZM12.9222 16H7V17.6667C7 18.265 7.48215 18.75 8.07692 18.75H12V18.2C12 17.2738 12.4322 16.5065 12.9222 16ZM22 13.0503C21.772 12.7309 21.5083 12.448 21.2266 12.2065C20.604 11.6729 19.8321 11.2832 19 11.1061V8H20.9231C21.5178 8 22 8.48502 22 9.08333V13.0503Z"
                :fill="color"
            />
            <path
                d="M22 18.3V21.8C22 22.4 21.4 23 20.7 23H15.2C14.6 23 14 22.4 14 21.7V18.2C14 17.6 14.6 17 15.2 17V15.5C15.2 14.1 16.6 13 18 13C19.4 13 20.8 14.1 20.8 15.5V17C21.4 17 22 17.6 22 18.3ZM19.5 15.5C19.5 14.7 18.8 14.2 18 14.2C17.2 14.2 16.5 14.7 16.5 15.5V17H19.5V15.5Z"
                :fill="color"
            />
            <!-- eslint-enable max-len -->
        </template>
    </AppIcon>
</template>
