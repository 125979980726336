<script
    lang="ts"
    setup
>
    const popoverRef = ref<ReturnType<typeof defineComponent>>()

    const busUnsubscribe = useEventBus().on(BUS_EVENT_SIDEBAR_OPEN_TIPS, () => popoverRef.value.open())

    onBeforeUnmount(() => busUnsubscribe())
</script>

<template>
    <TheSidebarPopover
        ref="popoverRef"
        popover-class="min-w-[310px] p-6"
    >
        <template #activator="{ active, toggle }">
            <AppTooltip direction="right">
                <template #activator="{ open, close }">
                    <TheSidebarActionTipsActivator
                        :active="active"
                        @mouseenter.passive="open"
                        @mouseleave.passive="close"
                        @click="toggle()"
                    />
                </template>

                {{ $t('tips') }}
            </AppTooltip>
        </template>

        <template #default="{ close }">
            <TheSidebarActionTipsContent @close="close"/>
        </template>
    </TheSidebarPopover>
</template>
