<script
    lang="ts"
    setup
>
    import { useUserStore } from '~/stores/user'

    const userStore = useUserStore()
</script>

<template>
    <div class="relative flex flex-col items-start justify-between min-w-[84px] h-full p-2 bg-white">
        <div class="sidebar-navigation">
            <TheSidebarNavigationItems/>
        </div>

        <div class="sidebar-actions">
            <TheSidebarActionTips
                v-if="userStore.isOperatorEnabled && userStore.currentOperator.onboarding"
                key="tips"
            />

            <TheSidebarActionAccount key="account"/>

            <TheSidebarActionWorkStatus
                v-if="userStore.isOperatorEnabled"
                key="work-status"
            />
        </div>
    </div>
</template>

<style
    lang="sass"
    scoped
>
    .sidebar
        &-navigation,
        &-actions
            @apply flex flex-col items-center gap-4 w-[68px] my-2
</style>
