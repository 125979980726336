<script
    lang="ts"
    setup
>
    import { useUserStore } from '~/stores/user'
    import { useChatStore } from '~/stores/chat'

    const popoverRef = ref<ReturnType<typeof defineComponent>>()

    const userStore = useUserStore()
    const chatStore = useChatStore()

    const projectText = computed<string>(() => userStore.currentProject?.name?.[0])

    const showNotificationBadge = computed<boolean>(() => {
        for (const projectId in chatStore.isThereChatWithUnreadMessagesInOtherProjectsByProjectId) {
            if (chatStore.isThereChatWithUnreadMessagesInOtherProjectsByProjectId[projectId]) {
                return true
            }
        }

        return false
    })
</script>

<template>
    <TheSidebarPopover
        ref="popoverRef"
        popover-class="min-w-[272px] py-4 px-0"
    >
        <template #activator="{ active, toggle }">
            <AppButton
                wrapper
                @click="toggle()"
            >
                <AppAvatar
                    key="avatar"
                    :src="userStore.currentProject?.logo?.thumbnails?.v120x120"
                    class="cursor-pointer"
                >
                    <div class="text-[24px] font-[700] leading-[31px] uppercase">
                        {{ projectText }}
                    </div>

                    <template #after-content>
                        <div class="absolute flex -right-1 -bottom-1 bg-white origin-center rounded-[50%]">
                            <AppIconChevronDown
                                size="20"
                                color="#aaadB8"
                                :rotate="active"
                            />
                        </div>
                    </template>
                </AppAvatar>

                <Transition name="scale-transition">
                    <AppNotificationBadge
                        v-if="showNotificationBadge"
                        key="notification"
                        class="top-[3px] right-[3px] !w-[12px] !h-[12px]"
                    />
                </Transition>
            </AppButton>
        </template>

        <div class="flex flex-col gap-1 w-full">
            <TheSidebarActionAccountUserInfo/>

            <TheSidebarActionAccountProjects @close="popoverRef.close()"/>

            <AppDivider class="!mb-1"/>

            <TheSidebarActionAccountNavigation @close="popoverRef.close()"/>
        </div>
    </TheSidebarPopover>
</template>
