<script
    lang="ts"
    setup
>
    import { useUserStore } from '~/stores/user'

    const userStore = useUserStore()
</script>

<template>
    <div class="px-4 text-[16px] font-medium leading-[130%]">
        {{ userStore.user.name }}
    </div>

    <div class="px-4 text-[14px] leading-[130%]">
        {{ userStore.user.email }}
    </div>
</template>
