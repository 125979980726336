<script
    lang="ts"
    setup
>
    const MAX_AUTO_RECONNECTION_ATTEMPTS = 5
    const INITIAL_REMAIN_SECONDS = 2

    const { isConnected, isConnecting, connect, onDisconnected, onFetchTokenFailed } = useBroadcaster()
    const { onOnline } = useNetwork()
    const { onTabActive } = useTabActivity()
    const { maxTablet } = useWindowSize()

    const reconnection = ref<boolean>(false)
    const reconnectionAttempt = ref<number>(0)
    const remainSecondsNext = ref<number>(INITIAL_REMAIN_SECONDS)
    const remainSeconds = ref<number>(remainSecondsNext.value)

    const autoReconnectionEnabled = computed<boolean>(() => reconnectionAttempt.value < MAX_AUTO_RECONNECTION_ATTEMPTS)
    const showModal = computed<boolean>(() => reconnectionAttempt.value > 0)
    const showAction = computed<boolean>(() => reconnectionAttempt.value > 2)

    const autoReconnectionTimer = useTimer(() => {
        remainSeconds.value--

        if (!remainSeconds.value) {
            reconnect()
        }
    }, 1000, { interval: true })

    const reconnect = (): void => {
        autoReconnectionTimer.stop()

        if (reconnection.value) {
            return
        }

        reconnection.value = true

        connect()
    }

    const runAutoReconnection = (): void => {
        if (!autoReconnectionEnabled.value) {
            return
        }

        remainSeconds.value = remainSecondsNext.value

        autoReconnectionTimer.start()
    }

    onMounted(async () => {
        if (isConnecting.value) {
            await untilCondition(() => !isConnecting.value)

            if (isConnected.value) {
                return
            }
        }

        onDisconnected(() => {
            reconnection.value = false
            remainSecondsNext.value = remainSecondsNext.value * 2
            reconnectionAttempt.value++

            runAutoReconnection()
        })

        onFetchTokenFailed(() => {
            autoReconnectionTimer.stop()

            reconnection.value = false
            remainSeconds.value = remainSecondsNext.value = INITIAL_REMAIN_SECONDS
            reconnectionAttempt.value = MAX_AUTO_RECONNECTION_ATTEMPTS
        })

        onOnline(() => {
            if (!autoReconnectionEnabled.value) {
                reconnect()
            }
        })

        onTabActive(() => {
            if (!autoReconnectionEnabled.value) {
                reconnect()
            }
        })

        reconnect()
    })
</script>

<template>
    <AppModal
        v-if="showModal"
        impulsive-transition
        :version-bottom="maxTablet"
        content-class="!max-w-[390px]"
    >
        <template #icon>
            <AppIconWebRefresh size="34"/>
        </template>

        <template #title>
            <div class="text-center">
                {{ $t('broadcaster-reconnection-1') }}
            </div>
        </template>

        <AppHint
            key="hint"
            class="text-center"
        >
            <template v-if="showAction">
                {{ $t(autoReconnectionEnabled ? 'broadcaster-reconnection-5' : 'broadcaster-reconnection-6') }}
            </template>

            <template v-else>
                {{ $t('broadcaster-reconnection-4') }}
                {{ remainSeconds }}{{ $t('broadcaster-reconnection-3') }}
            </template>
        </AppHint>

        <AppButton
            v-if="showAction"
            key="action"
            :loading="reconnection"
            class="mt-8"
            @click="reconnect()"
        >
            {{ $t('broadcaster-reconnection-2') }}

            <template v-if="autoReconnectionEnabled">
                {{ remainSeconds }}{{ $t('broadcaster-reconnection-3') }}
            </template>
        </AppButton>
    </AppModal>
</template>
