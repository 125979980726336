<template>
    <AppIcon v-bind="$props">
        <template #default="{ color }">
            <path
                d="M12 2L1 21H23M12 6L19.53 19H4.47M11 10V14H13V10M11 16V18H13V16"
                :fill="color"
            />
        </template>
    </AppIcon>
</template>
