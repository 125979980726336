<script
    lang="ts"
    setup
>
    import { useAppStore } from '~/stores/app'
    import { useUserStore } from '~/stores/user'

    const appStore = useAppStore()
    const userStore = useUserStore()

    const closeOnboardingCongratulationsOnRegistration = (): void => {
        appStore.showCongratulationsOnRegistrationModal = false

        useEventBus().emit(BUS_EVENT_SIDEBAR_OPEN_TIPS)
    }
</script>

<template>
    <AppModal
        content-class="!max-w-[400px] !p-0"
        @close="closeOnboardingCongratulationsOnRegistration()"
    >
        <div :class="$style['onboarding-congratulations']">
            <img
                src="~/assets/img/onboarding-1.png"
                alt="Onboarding 1"
                draggable="false"
            />

            <div :class="$style['content']">
                <div :class="$style['title']">
                    {{ $t('congratulations') }}, {{ userStore.user?.name }}!
                </div>

                <!-- eslint-disable vue/no-v-html -->
                <div
                    :class="$style['text']"
                    v-html="$t('onboarding-after-registration-modal-text')"
                ></div>
                <!-- eslint-enable vue/no-v-html -->

                <AppButton
                    :class="$style['action']"
                    @click="closeOnboardingCongratulationsOnRegistration()"
                >
                    {{ $t('close') }}
                </AppButton>
            </div>
        </div>
    </AppModal>
</template>

<style
    lang="sass"
    module
    scoped
>
    .onboarding-congratulations
        overflow: hidden
        display: flex
        flex-direction: column
        border-radius: inherit

        .content
            display: flex
            flex-direction: column
            padding: 16px 32px 32px

            .title
                margin-bottom: 8px
                font-size: 20px
                font-weight: 500
                line-height: 24px

            .text
                font-size: 14px
                font-weight: 400
                line-height: 130%

            .action
                margin-top: 24px
</style>
