<script
    lang="ts"
    setup
>
    import { useAppStore } from '~/stores/app'
    import { useUserStore } from '~/stores/user'

    const appStore = useAppStore()
    const userStore = useUserStore()

    const { isOnline } = useNetwork()
    const { isConnected } = useBroadcaster()
    const { onMaxTabletChange } = useWindowSize()

    const showNavbar = ref<boolean>(false)

    onMaxTabletChange(v => (showNavbar.value = v))
</script>

<template>
    <div class="relative flex flex-col w-full h-full backface-invisible bg-white text-black">
        <AppTransitionCollapse>
            <AppBanner
                v-if="appStore.showBanner"
                key="banner"
            />
        </AppTransitionCollapse>

        <ClientOnly>
            <TheBroadcasterReconnectionModal
                v-if="isOnline && userStore.isOperatorEnabled && !isConnected"
                key="broadcaster-reconnection-modal"
            />

            <TheCongratulationsOnRegistrationModal
                v-if="appStore.showCongratulationsOnRegistrationModal"
                key="congratulations-modal"
            />

            <AppMediaViewer key="media-viewer"/>
        </ClientOnly>

        <main
            key="main"
            class="flex flex-1 overflow-hidden rounded-[16px_16px_0_0]"
        >
            <TheSidebar
                v-if="!showNavbar"
                key="sidebar"
            />

            <slot key="page"/>
        </main>

        <TheNavbar
            v-if="showNavbar"
            key-="navbar"
        />
    </div>
</template>
